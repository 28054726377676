import {
  Autocomplete as AutocompleteComponent,
  AutocompleteOptions,
  AutocompleteInput,
  AutocompleteProps,
  a11yHelpers,
} from '@flixbus/honeycomb-react';
import React, { useState } from 'react';
import { TextWithEllipsis } from 'app/components/ui/TextWithEllipsis/TextWithEllipsis';
import { Icon } from 'app/icons';
import classNames from 'classnames';
import styles from './Autocomplete.scss';
import { requestAutocomplete } from 'app/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useResults } from 'app/hooks/useResults';
import cn from 'classnames';
import { IconComponent } from '@flixbus/honeycomb-icons-react/src/components/icons/types';
import { AutocompleteOptionType } from '@flixbus/honeycomb-react/src/components/autocomplete/autocomplete-options/types';
import { GroupTitle } from './GroupTitle';
import { Option } from './Option';
import { useAutocomplete } from '../hooks/useAutocomplete';
import { useTripLocation } from 'app/components/tripLocation/hooks/useTripLocation';
import { openedOverlaySelector } from 'app/store/selectors';
import { emitFieldOpenedEvent } from 'app/events';
import { FIELD_ARRIVAL_CITY, FIELD_DEPARTURE_CITY } from 'app/storage/fieldNames';
import { closeElement } from 'app/functions/general/closeElement';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { useTranslations } from 'app/hooks/useTranslations';

export type AutocompleteTypes = {
  direction: 'from' | 'to';
  afterSelectAction?: () => void;
  icon?: IconComponent;
  label?: string;
  extraClasses?: string;
  tabTrapAction?: (e: React.KeyboardEvent) => void;
};

export const Autocomplete = ({
  direction,
  afterSelectAction,
  icon,
  label,
  extraClasses,
  tabTrapAction,
  ...autocompleteProps
}: AutocompleteTypes & Partial<AutocompleteProps>) => {
  const openedOverlayName = useSelector(openedOverlaySelector)?.name;
  const isAlwaysExpanded: boolean = openedOverlayName === `TripLocationPanel-${direction}`;
  const { message, value, setValue } = useTripLocation(direction);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();

  const {
    type: resultsType,
    options,
    citiesById,
  } = useResults({ direction, hasQuery: !!value, isFocused, groupTitle: GroupTitle });

  const { getGroupResultsAmount } = useTranslations({
    getGroupResultsAmount: a11yLabelsKeys.locationInputs.groupResultsAmount,
  });

  const { data, onSelect, wrapperRef, selected } = useAutocomplete({
    direction,
    setValue,
    isFocused,
    setIsFocused,
    isAlwaysExpanded,
    options,
    citiesById,
    afterSelectAction,
    resultsType,
    value,
  });

  const visibleMessage = message && resultsType === 'autocomplete' ? message : null;

  return (
    <div ref={wrapperRef} className={extraClasses}>
      <AutocompleteComponent
        role="none"
        tabIndex={-1}
        options={data}
        onDebounce={async (e) => {
          setValue(e.target.value);
          await dispatch(
            requestAutocomplete(
              e.target.value ? e.target.value : '',
              direction,
              direction === 'to',
            ),
          );
        }}
        value={value}
        onSelect={onSelect}
        extraClasses={cn(styles.autocomplete)}
        onBlur={() => {
          if (isAlwaysExpanded) {
            return null;
          }

          closeElement(direction === 'from' ? FIELD_DEPARTURE_CITY : FIELD_ARRIVAL_CITY);
        }}
        {...autocompleteProps}
      >
        <AutocompleteInput
          id={`searchInput-${direction}`}
          label={label ? <TextWithEllipsis text={label} /> : ''}
          autoComplete="off"
          iconLeft={icon && <Icon aria-hidden appearance="primary" InlineIcon={icon} />}
          extraClasses={classNames(styles.input, { [styles.arrivalInput]: direction === 'to' })}
          onFocus={() => {
            if (!isAlwaysExpanded) {
              emitFieldOpenedEvent(
                direction === 'from' ? FIELD_DEPARTURE_CITY : FIELD_ARRIVAL_CITY,
              );
            }
            setValue('');
            setIsFocused(true);
          }}
          placeholder={selected.stationName || selected.name}
          onKeyDown={tabTrapAction}
          info={
            isFocused ? (
              <span className={a11yHelpers().srOnly} aria-live="polite">
                {getGroupResultsAmount({}, data.length)}
              </span>
            ) : null
          }
        />
        <AutocompleteOptions
          role="presentation"
          optionsToDisplay={10}
          label=""
          renderOption={(item: AutocompleteOptionType) => (
            <Option
              value={value}
              resultType={resultsType}
              item={item}
              citiesById={citiesById}
              direction={direction}
            />
          )}
          extraClasses={styles.options}
        />
      </AutocompleteComponent>
      {visibleMessage && isFocused && <div className={styles.errorMessage}>{visibleMessage}</div>}
    </div>
  );
};
